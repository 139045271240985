import { Multilingual } from 'interfaces/offer.interface';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { AppUrlPath } from 'utils/app-urls';
import { config } from 'utils/config';
import HeadMetadataLangAlternate from './HeadMetadataLangAlternate';

type HeadMetadataProps = {
  title: string;
  desc?: string | null;
  url?: AppUrlPath;
  imgUrl?: string;
  path?: Multilingual;
  indexable?: boolean;
};

const HeadMetadata: React.FC<HeadMetadataProps> = ({
  title,
  url,
  desc = '',
  imgUrl,
  path,
  indexable = false
}) => {
  const { locale } = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>

        <meta name="description" content={desc || ''} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc || ''} />
        {imgUrl && <meta property="og:image" content={imgUrl} />}

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="@getmanfred"></meta>
        <meta name="twitter:creator" content="@getmanfred"></meta>
        <meta name="twitter:title" content={title}></meta>
        <meta name="twitter:description" content={desc || ''}></meta>
        {imgUrl && <meta name="twitter:image" content={imgUrl}></meta>}

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc || ''} />

        {imgUrl && (
          <meta
            property="og:image"
            content="https://www.getmanfred.com/og-image.jpg"
          />
        )}

        <meta property="og:site_name" content="Get Manfred" />
        <meta property="og:locale" content={locale} />

        {url && (
          <>
            <meta
              property="og:url"
              content={`${config.appUrl}${url.langs['es']}`}
            />
            <link rel="canonical" href={`${config.appUrl}${url.langs['es']}`} />
          </>
        )}

        {indexable && <meta name="robots" content="index,follow" />}
      </Head>

      <Script
        id="globalLDMetadata"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Manfred",
          "url": "https://www.getmanfred.com/",
          "logo": "https://www.getmanfred.com/favicon.ico",
          "description": "Plataforma de empleo para profesionales del sector tecnológico.",
          "sameAs": [
            "https://twitter.com/getmanfred",
            "https://www.linkedin.com/company/getmanfred/",
            "https://github.com/getmanfred>"
          ],
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "email": "support@getmanfred.com",
              "contactType": "customer service",
              "availableLanguage": ["Spanish", "English"]
            }
          ],
          "founder": {
            "@type": "Person",
            "name": "David Bonilla",
            "url": "https://twitter.com/david_bonilla>"
          },
          "foundingDate": "2019",
          "areaServed": "Worldwide",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "ES",
            "addressRegion": "Madrid",
            "addressLocality": "Madrid"
          },
          "offers": {
            "@type": "Service",
            "name": "Plataforma de empleo para el sector tech",
            "description": "Conectamos profesionales tecnológicos con empresas mediante un enfoque centrado en la privacidad y la transparencia."
          }
        }`
        }}
      ></Script>

      <HeadMetadataLangAlternate url={url} path={path} />
    </>
  );
};

export default HeadMetadata;
